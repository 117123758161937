export const colors = {
  transparent: 'transparent',
  black: {
    '400': '#212121', // Steel Black
    '500': '#000',
  },
  white: { '500': '#fff' },
  canvas: { '500': '#FFFFF6' },
  overlay: { '500': 'rgba(0,0,0,0.7)' },
  border: { '500': 'rgba(0,0,0,0.5)' },
  offGrey: {
    '50': '#fff7ea',
    '100': '#fdf0e3',
    '200': '#f3e7da',
    '300': '#e3d7cb',
    '400': '#bfb4a7',
    //changed new color for steelhouse 2024 ONG-4403
    '500': '#E7D6C5', // new Brand Off Grey
    // '500': '#9F9488', // old Brand Off Grey
    '600': '#766c60',
    '700': '#62584d',
    '800': '#433a30',
    '900': '#211a10',
  },
  grey: {
    '50': '#f9f9f4',
    '100': '#f2f2ed',
    //changed new color for steelhouse 2024 ONG-4403
    '200': '#E4E3DD', // new Brand Light Grey
    // '200': '#E9E9E4', // old Brand Light Grey
    '300': '#d9d9d4',
    '400': '#b5b5b0',
    '500': '#959591',
    '600': '#6d6d69',
    '700': '#595955',
    '800': '#3b3b37',
    '900': '#1b1b17',
  },
  darkGrey: {
    '50': '#f3f9fc',
    '100': '#eaf0f4',
    '200': '#dfe5e8',
    '300': '#cdd3d7',
    '400': '#a9aeb2',
    '500': '#888e91',
    '600': '#616669',
    '700': '#4E5356', // Brand Dark Grey
    '800': '#303538',
    '900': '#101518',
  },
  offGreen: {
    '50': '#e6f0ea',
    '100': '#c4dbcb',
    '200': '#a2c5ab',
    '300': '#84ae8e',
    '400': '#739d7c',
    '500': '#688c6c',
    '600': '#607f63',
    '700': '#567057',
    //changed new color for steelhouse 2024 ONG-4403
    '800': '#BB5280', // new Brand Off Pink
    // '800': '#4C604C', // old Brand Off Green
    '900': '#3b4439',
  },
  brokenOrange: {
    '50': '#f8ebe9',
    '100': '#f9d0c1',
    '200': '#f7b399',
    '300': '#f59770',
    '400': '#f38151',
    '500': '#f26e34',
    '600': '#e86830',
    '700': '#da612c',
    '800': '#cb5a2a',
    //changed new color for steelhouse 2024 ONG-4403
    '900': '#766480', // new Brand Broken Purple
    // '900': '#B14F25', // old Brand Broken Orange
  },
  goldenBrown: {
    '50': '#fffbe8',
    '100': '#fff3c6',
    '200': '#feeda2',
    '300': '#fce67e',
    '400': '#fae063',
    '500': '#f7db4b',
    '600': '#e7c946',
    '700': '#d2b43f',
    '800': '#bd9e3a',
    //changed new color for steelhouse 2024 ONG-4403
    '900': '#7DB2D8', // new Brand Baby Blue
    // '900': '#9A7931', // old Brand Golden Brown
  },
  brown: {
    '50': '#fff7ec',
    '100': '#fbf1e5',
    '200': '#f2e8dc',
    '300': '#e2d8cc',
    '400': '#beb4a9',
    '500': '#9e948a',
    '600': '#756c62',
    '700': '#61594f',
    //changed new color for steelhouse 2024 ONG-4403
    '800': '#487458', // new Brand Green
    // '800': '#423A31', // old Brand Brown
    '900': '#211a11',
  },
  yellow: {
    '50': '#fdffe8',
    '100': '#f9fdc2',
    '200': '#f2fb97',
    '300': '#ebf969',
    '400': '#e4f540',
    '500': '#ecff18', // Brand Yellow
    '600': '#deeb0c',
    '700': '#ccd400',
    '800': '#bcbc00',
    '900': '#a29400',
  },
};
